export default function Services() {
  const services = [
    {
      title: 'Portfolio analyser',
      description:
        'Upload an existing equity portfolio (historical or point in time) and receive a comprehensive set of analytics (e.g. performance, risk-analysis, ESG, impact).',
    },

    {
      title: 'AI alpha engine',
      description:
        'Leverage advanced machine learning algorithms that provide daily expected returns predictions on 25,000+ stocks, used as input in the portfolio optimisation engine.',
    },
    {
      title: 'Portfolio Builder',
      description:
        'Construct or refine an equity portfolio using a universe filtering and portfolio optimisation engine – with the ability to define countless objectives and constraints.',
    },
    {
      title: 'Backtesting engine',
      description:
        'Generate a 9+ year backtest for an equity portfolio and receive a comprehensive set of historical analytics in minutes.',
    },
    {
      title: 'Performance attribution',
      description:
        'Conduct performance attribution on an equity portfolio – decomposing excess return versus a selected benchmark, across risk factors, industries, and countries.',
    },

    {
      title: 'Sustainability & values integration',
      description:
        'Incorporate sustainability factors within an existing or newly created equity portfolio – including: ESG, Climate, Impact, Shariah.',
    },
    {
      title: 'Client tools',
      description:
        'Interact with the offering directly on the web-app, or via APIs and Python SDKs. Portfolio delivery is also possible via a range of file transfer protocols.',
    },
    {
      title: 'Factsheet Generator',
      description:
        'Create a white-labelled factsheet using the platform, automating the production and delivery of client reporting.',
    },
  ];

  return (
    <div className="mb-0 bg-gray-700 py-24 sm:py-20">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto text-center lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Our offering</h2>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            Our platform combines a range of building blocks to enhance portfolio construction and reporting
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
            {services.map(({ title, description }, index) => (
              <div className="relative pl-9 text-white" key={index}>
                <dt className="text-lg font-bold">
                  <svg
                    className="absolute left-0 top-1 h-5 w-5 text-indigo-500"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {title}
                </dt>
                <dd className="mt-2 text-sm">{description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
