import React, { useEffect } from 'react';
import { OmniSearch as OmniSearchUI, type Data, type Item } from '@aether/ui/app-shared/OmniSearch';
import { getAppOrigin } from '@aether/utils/services/linking-service';

type Fund = {
  identifier: string;
  name?: string;
  rawStrategyId?: string | null;
  equityCarveOutStrategyId?: string | null;
  aiOverlayStrategyId?: string | null;
  parentIdentifier?: string;
};

const searchUri = typeof window !== 'undefined' ? window.ENV.FUND_SEARCH_URI : '';

export const OmniSearch = () => {
  const [funds, setFunds] = React.useState<Data>();
  const [hasLocalFundsData, setHasLocalFundsData] = React.useState<Fund[] | undefined>();

  useEffect(() => {
    fetch(`${searchUri}/funds`)
      .then(response => response.json() as Promise<Fund[]>)
      .then(data => {
        setFunds({
          sectionLabel: 'Funds',
          loading: false,
          items: mapFundsToItem(data),
        });
        setHasLocalFundsData(data);
      });
  }, []);

  const onQueryChange = (value: string) => {
    if (!hasLocalFundsData) {
      // While loading the full list, we can make this component already usable by fetching little peaces on demand
      setFunds({
        sectionLabel: 'Funds',
        loading: true,
        items: [],
      });
      fetch(`${searchUri}/funds?q=${value}&limit=10`)
        .then(response => response.json() as Promise<Fund[]>)
        .then(data => {
          setFunds({
            sectionLabel: 'Funds',
            loading: false,
            items: mapFundsToItem(data),
          });
        });
    }
  };

  const uniqueFunds = hasLocalFundsData ? new Set(funds?.items.map(f => f.parentIdentifier)).size : 0;

  return (
    <>
      <OmniSearchUI
        hasKeyboardOpenShortcut
        data={funds ? [funds] : []}
        onQueryChange={onQueryChange}
        placeholder="Enter your identifier or fund name..."
        hideListOnEmptySearch
      />
      {uniqueFunds > 0 && (
        <div className="mt-1 text-xs text-gray-500">
          Find your fund among the <strong>{uniqueFunds}</strong> unique funds currently available.
        </div>
      )}
    </>
  );
};

const mapFundsToItem = (funds: Fund[]): Item[] =>
  funds.flatMap(fund =>
    fund.equityCarveOutStrategyId && fund.aiOverlayStrategyId
      ? [
          {
            id: fund.identifier,
            name: fund.name,
            url: `${getAppOrigin()}/funds/${fund.identifier}`,
            parentIdentifier: fund.parentIdentifier,
          },
        ]
      : []
  );
