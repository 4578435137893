import React, { useEffect, useState, type PropsWithChildren } from 'react';
import { debounce } from '@aether/utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { ButtonNew } from '../../ButtonNew';
import { Dialog } from '../../Dialog';
import { Icon } from '../../Icon';
import { Spinner } from '../../Spinner';
import { TextField } from '../../TextField';
import { cn } from '../../utils';

export type Item = {
  id: string;
  name?: string;
  url?: string; // whether or not the item is supported
  parentIdentifier?: string;
  dateCreated?: string;
};

export type Data = {
  sectionLabel: string;
  loading: boolean;
  items: Item[];
};

export type FundItemType = (props: PropsWithChildren<{ data: Item; isSelected: boolean }>) => JSX.Element;

export type OmniSearchProps = {
  data: Data[];
  onQueryChange?: (query: string) => void;
  itemUrl?: (data: Item) => string;
  isOpen?: boolean;
  placeholder?: string;
  children?: React.ReactNode;
  hasKeyboardOpenShortcut?: boolean;
  hideListOnEmptySearch?: boolean;
};

export const OmniSearch = ({
  data,
  onQueryChange,
  isOpen,
  placeholder = 'Search for ISIN or name …',
  itemUrl,
  children,
  hasKeyboardOpenShortcut,
  hideListOnEmptySearch,
}: OmniSearchProps) => {
  const location = useLocation();
  const [isSearchOpen, setIsSearchOpen] = useState(Boolean(isOpen));

  useEffect(() => {
    // Handle navigation changes from Link components
    setIsSearchOpen(false);
  }, [location]);

  useEffect(() => {
    setIsSearchOpen(Boolean(isOpen));
  }, [isOpen]);

  useEffect(() => {
    if (!hasKeyboardOpenShortcut) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') setIsSearchOpen(true);
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [hasKeyboardOpenShortcut]);

  return (
    <Dialog.Root open={isSearchOpen} onOpenChange={open => setIsSearchOpen(open)}>
      <Dialog.Trigger asChild>
        {children ? (
          children
        ) : (
          <ButtonNew
            variant="function"
            className="relative min-h-10 w-full overflow-x-hidden rounded border border-border-medium bg-white text-text-secondary outline-none"
          >
            <Icon icon="ic:outline-search" className="absolute left-2 text-[18px] text-gray-400" />
            <span className="absolute left-8">{placeholder}</span>
          </ButtonNew>
        )}
      </Dialog.Trigger>
      <Dialog.Content
        overlayOpacity="bg-opacity-50"
        className={cn(
          'w-full max-w-4xl bg-transparent p-0 shadow-none',
          hideListOnEmptySearch && '-translate-y-[248px] animate-overlay'
        )}
      >
        <Dialog.Body className="overflow-visible">
          <OmniSearchBody
            data={data}
            placeholder={placeholder}
            onQueryChange={onQueryChange}
            itemUrl={itemUrl}
            hideListOnEmptySearch={hideListOnEmptySearch}
          />
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
};

const OmniSearchBody = ({
  data,
  onQueryChange,
  placeholder,
  itemUrl = data => data.url || '',
  hideListOnEmptySearch = false,
}: OmniSearchProps) => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [query, setQuery] = React.useState<string | undefined>();

  const setQueryDebounced = debounce((value: string) => {
    if (value !== query) {
      setSelectedIndex(-1);
      setQuery(value);
      if (onQueryChange) onQueryChange(value);
    }
  }, 200);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex + 1) % itemsFlat.length);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex - 1 + itemsFlat.length) % itemsFlat.length);
    } else if (event.key === 'Enter' && selectedItem?.url) {
      event.preventDefault();
      // Extract the path from the full URL
      const url = new URL(selectedItem.url);
      const path = url.pathname + url.search + url.hash;
      navigate(path);
    }
  };

  const filtered = filterByQuery(data, query);
  const itemsFlat = filtered.flatMap(data => (data.items.length > 50 ? paginate(data.items, 0, 50) : data.items));
  const selectedItem: Item | undefined = itemsFlat[selectedIndex];

  const keyClasses = 'flex h-5 w-5 items-center justify-center rounded-sm p-0.5 font-bold';
  const keyStyles = {
    background: 'linear-gradient(-225deg, #d5dbe4, #f8f8f8)',
    boxShadow: 'inset 0 -2px 0 0 #cdcde6, inset 0 0 1px 1px #fff, 0 1px 2px 1px rgba(30, 35, 90, 0.4)',
  };

  return (
    <div
      className={cn('relative max-h-[520px] overflow-hidden rounded-lg border border-border-dark bg-white shadow-card')}
    >
      <TextField
        onKeyDown={handleKeyDown}
        name="isin"
        fieldGroupClassName="h-14 rounded-none border-0 border-b border-b-gray-300"
        fieldClassName="text-lg"
        iconBeforeClassName="ml-2 text-2xl"
        iconBefore="ic:outline-search"
        placeholder={placeholder}
        onKeyUp={e => setQueryDebounced((e.target as HTMLInputElement).value)}
      />
      <div className={hideListOnEmptySearch && !query ? 'hidden' : ''}>
        <FundsListElement {...{ data: filtered, selectedItem, itemUrl }} />
        <div className="flex items-center justify-end gap-4 bg-gray-100 p-2 pb-2.5 text-sm text-gray-500">
          <div className="flex items-center gap-1">
            {isWindows() ? (
              <Icon aria-label="CTRL Key" icon="vaadin:ctrl-a" className={keyClasses} style={keyStyles} />
            ) : (
              <Icon
                aria-label="CMD Key"
                icon="ic:outline-keyboard-command-key"
                className={keyClasses}
                style={keyStyles}
              />
            )}
            <div className={keyClasses} style={keyStyles}>
              K
            </div>
            to open
          </div>
          <div className="flex items-center gap-1">
            <Icon aria-label="Arrow Up" icon="ic:outline-arrow-upward" className={keyClasses} style={keyStyles} />
            <Icon
              aria-label="Arrow Down"
              icon="ic:outline-arrow-downward"
              className={keyClasses}
              style={keyStyles}
            />{' '}
            to navigate
          </div>
          <div className="flex items-center gap-1">
            <Icon aria-label="Enter Key" icon="ic:outline-keyboard-return" className={keyClasses} style={keyStyles} />{' '}
            to select
          </div>
          <div className="flex items-center gap-1">
            <Icon aria-label="Escape Key" icon="mdi:keyboard-esc" className={keyClasses} style={keyStyles} />
            to close
          </div>
        </div>
      </div>
    </div>
  );
};

const FundsListElement = ({
  data,
  selectedItem,
  itemUrl,
}: {
  selectedItem?: Item;
  data: Data[];
  itemUrl: (data: Item) => string;
}) => {
  const scrollElRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!scrollElRef.current || data[0]?.items[0]?.id !== selectedItem?.id) return;
    scrollElRef.current.scrollTop = 0;
  }, [data, selectedItem, scrollElRef]);

  return (
    <div className="max-h-[400px] w-full overflow-auto" ref={scrollElRef}>
      {data.map(data => {
        const totalItems = data.items.length;

        if (data.loading)
          return (
            <FundsListElementHeader
              key={data.sectionLabel}
              section={data.sectionLabel}
              headerChild={
                <>
                  {' '}
                  - Loading… <Spinner className="absolute bottom-2 right-2 h-5" />
                </>
              }
            />
          );

        if (totalItems === 0)
          return (
            <FundsListElementHeader
              key={data.sectionLabel}
              section={data.sectionLabel}
              headerChild={<> - No results found</>}
            />
          );

        // We still limit results due to rendering times, although the number could be higher (no real pagination for now just stripping out)
        const paginated = totalItems > 50 ? paginate(data.items, 0, 50) : data.items;
        const itemsRemaining = Math.abs(totalItems - paginated.length);

        return (
          <FundsListElementHeader key={data.sectionLabel} section={data.sectionLabel}>
            <FundList {...{ data: paginated, selectedItem, itemUrl }} />
            {itemsRemaining ? (
              <p className="px-3 py-2 text-center">
                <em>… {itemsRemaining} more results available, enter more specific search terms to display them …</em>
              </p>
            ) : null}
          </FundsListElementHeader>
        );
      })}
    </div>
  );
};

const FundsListElementHeader = ({
  section,
  headerChild,
  children,
}: PropsWithChildren<{ section: string; headerChild?: React.ReactElement }>) => (
  <div className="relative h-full w-full border-b border-b-gray-200 py-3">
    <p className="mb-2 px-3 text-left text-xs font-semibold">
      {section}
      {headerChild}
    </p>
    {children}
  </div>
);

const FundList = ({
  data,
  selectedItem,
  itemUrl,
}: {
  data?: Item[];
  selectedItem?: Item;
  itemUrl: (data: Item) => string;
}) => {
  if (data && !data.length) {
    return (
      <div className="mb-2 p-2 px-3">
        <p className="p-2 font-semibold">Sorry, we don’t currently support this item at the moment.</p>
        <p className="p-2">Would you like to have it added?</p>
        <div className="p-2 text-right">
          <ButtonNew asChild>
            <a href={`https://www.arabesque.com/ai/contact-us/`}>Contact us</a>
          </ButtonNew>
        </div>
      </div>
    );
  }

  return data
    ? data.map(item => (
        <FundItem {...{ isSelected: selectedItem?.id === item.id, data: item, key: item.id, itemUrl }} />
      ))
    : null;
};

const FundItem = ({
  data,
  isSelected,
  itemUrl,
}: {
  isSelected: boolean;
  data: Item;
  itemUrl: (data: Item) => string;
}) => {
  const aRef = React.useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (isSelected && aRef.current) {
      aRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [isSelected, aRef]);

  return (
    <div title={data.name} className="w-full px-2 pb-1 text-left">
      <Link
        ref={aRef}
        to={itemUrl(data)}
        className={cn(
          'font-roboto grid w-full grid-cols-[1fr_auto] gap-2 overflow-hidden rounded-lg p-2 py-3 text-gray-800 transition-all hover:bg-gray-100 hover:text-black',
          isSelected ? 'bg-gray-100 text-black' : ''
        )}
      >
        <div className="w-full">{data.name}</div>
        <span className="text-gray-400">{data.id}</span>
      </Link>
    </div>
  );
};

const filterByQuery = (data: Data[], query?: string | null) => {
  if (!query) return data;
  const lowerCaseQuery = query.toLocaleLowerCase();
  const searchArray = lowerCaseQuery.split(' ');

  return data.map(data => ({
    loading: data.loading,
    sectionLabel: data.sectionLabel,
    items: data.items.filter(item =>
      searchArray.every(word => item.id.toLowerCase().includes(word) || item.name?.toLowerCase().includes(word))
    ),
  }));
};

const paginate = <T extends Item[]>(data: T, offset = 0, limit?: number): Item[] =>
  !limit ? data : data.slice(offset, offset + limit);

const isWindows = () => navigator.userAgent.indexOf('Windows') !== -1;
